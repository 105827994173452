import React from "react";
import { Link } from "react-router-dom";
import "./Secret.scss";

export function Secret() {
    return (
        <div className="content-wrap secret">
            <Link to="/emails">
                <div className="card">
                    <div className="title">Email List</div>
                    <span>Click to view/add/delete registered emails.</span>
                </div>
            </Link>
            <Link to="/booklets">
                <div className="card">
                    <div className="title">PDF Booklets</div>
                    <span>Click to view/upload/delete stored booklets.</span>
                </div>
            </Link>
            <Link to="/albums">
                <div className="card">
                    <div className="title">Gallery</div>
                    <span>
                        Click to view/upload/delete albums and pictures.
                    </span>
                </div>
            </Link>
            <Link to="/posts">
                <div className="card">
                    <div className="title">Events and Updates</div>
                    <span>Click to view/create/delete posts.</span>
                </div>
            </Link>
        </div>
    );
}
