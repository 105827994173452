import {
    onSnapshot,
    collection,
    writeBatch,
    query,
    where,
    orderBy,
    doc,
    addDoc,
    getDocs,
    deleteDoc,
    setDoc,
    getDoc,
    updateDoc,
    limit,
    startAt,
    startAfter,
    endAt,
    endBefore,
} from "firebase/firestore";
import { db } from "./config";

function path(collectionName) {
    const env = process.env.NODE_ENV || "production";
    const ret = `environments/${env}/${collectionName}`;
    return ret;
}

function toQueryConstraint(obj) {
    const { type, args } = obj;
    switch (type) {
        case "where":
            return where(...args);
        case "orderBy":
            return orderBy(...args);
        case "limit":
            return limit(...args);
        case "startAt":
            return startAt(...args);
        case "startAfter":
            return startAfter(...args);
        case "endAt":
            return endAt(...args);
        case "endBefore":
            return endBefore(...args);
        default:
            throw new Error("INVALID QUERY TYPE");
    }
}

// emails
function formatEmail(doc) {
    const ret = { email: doc.id };
    const data = doc.data();
    if (data.name) {
        ret.name = data.name;
    }
    return ret;
}

export function subscribeToEmails(callback) {
    return onSnapshot(collection(db, path("emails")), (snap) => {
        const emails = snap.docs.map(formatEmail);
        callback(emails);
    });
}

export async function getEmails() {
    const snap = await getDocs(collection(db, path("emails")));
    return snap.docs.map(formatEmail);
}

export async function addEmail(email, name = undefined) {
    await addEmails([email]);
}

export async function addEmails(emails = []) {
    const col = collection(db, path("emails"));
    if (emails.length === 0) {
        return;
    } else {
        const batch = writeBatch(db);
        for (const x of emails) {
            let email, name;
            if (typeof x === "string") {
                email = x;
            } else {
                email = x.email;
                name = x.name;
            }
            const ref = doc(col, email);
            batch.set(ref, name ? { name } : {});
        }
        await batch.commit();
    }
}

export async function setNameForEmail(email, name) {
    await setDoc(doc(db, path(`emails/${email}`)), { name });
}

export async function deleteEmail(email) {
    await deleteEmails([email]);
}

export async function deleteEmails(emails = []) {
    if (emails.length === 0) {
        return;
    } else {
        const col = collection(db, path("emails"));
        const batch = writeBatch(db);
        for (const email of emails) {
            const ref = doc(col, email);
            batch.delete(ref);
        }
        await batch.commit();
    }
}

// booklets

export function subscribeToBooklets(callback) {
    const q = query(collection(db, path("booklets")), orderBy("date", "desc"));
    return onSnapshot(q, (snap) => {
        const booklets = snap.docs.map((doc) => {
            const data = doc.data();
            return {
                ...data,
                date: data.date.toDate(),
                _id: doc.id,
            };
        });
        callback(booklets);
    });
}

export async function addBooklet(title, date, url, storagePath) {
    const col = collection(db, path("booklets"));
    await addDoc(col, { title, date, url, storagePath });
}

export async function deleteBooklet(id) {
    const col = collection(db, path("booklets"));
    await deleteDoc(doc(col, id));
}

// posts

function transformPostFromDB(doc, excludeEmailRecipients = true) {
    const data = doc.data();
    const date = data.date;
    let ret = {
        ...data,
        _id: doc.id,
        date: {
            created: date.created.toDate(),
            edited: date.edited ? date.edited.toDate() : null,
            published: date.published ? date.published.toDate() : null,
        },
    };
    if (data.type === "event") {
        const { start, end } = ret.eventDetails;
        ret = {
            ...ret,
            eventDetails: {
                ...ret.eventDetails,
                start: start ? start.toDate() : null,
                end: end ? end.toDate() : null,
            },
        };
    }
    if (excludeEmailRecipients) {
        delete ret.emailRecipients;
    }
    return ret;
}

export function subscribeToPosts(callback, ...qcs) {
    const queryConstraints = qcs.map(toQueryConstraint);
    const q = query(collection(db, path("posts")), ...queryConstraints);
    return onSnapshot(q, (snap) => {
        const docs = snap.docs.map(transformPostFromDB);
        callback(docs);
    });
}

export function subscribeToPostDraft(
    id,
    callback,
    excludeEmailRecipients = true
) {
    return onSnapshot(doc(db, path(`posts/${id}/drafts/current`)), (snap) => {
        if (snap.exists()) {
            callback(transformPostFromDB(snap, excludeEmailRecipients));
        } else {
            callback(null);
        }
    });
}

export async function getPostDraft(id) {
    const ref = doc(db, path("posts"), id, "drafts", "current");
    const snap = await getDoc(ref);
    return transformPostFromDB(snap, true);
}

export async function createNewPost() {
    const col = collection(db, path("posts"));
    const ref = await addDoc(col, {});
    await setDoc(doc(db, path(`posts/${ref.id}/drafts/current`)), {
        type: "update",
        title: "",
        content: "",
        date: {
            created: new Date(),
            edited: null,
            published: null,
        },
        emailRecipients: null,
        media: [],
    });
    return ref.id;
}

export async function updateDraft(id, update) {
    const ref = doc(db, path(`posts/${id}/drafts/current`));
    await updateDoc(ref, {
        ...update,
        committed: false,
        "date.edited": new Date(),
    });
}

export async function commitDraftedChanges(id) {
    const postRef = doc(db, path(`posts/${id}`));
    const draftRef = doc(db, postRef.path, "drafts", "current");
    const updates = (await getDoc(draftRef)).data();
    if ("committed" in updates) {
        delete updates.committed;
    }
    await Promise.allSettled([
        updateDoc(postRef, updates),
        updateDoc(draftRef, { committed: true }),
    ]);
}

export async function deletePost(id) {
    const ref = doc(db, path("posts"), id);
    await deleteDoc(ref);
}

// gallery
function transformAlbumFromDB(snap) {
    if (snap.exists) {
        const ret = {
            ...snap.data(),
            _id: snap.id,
        };
        ret.dateCreated = ret.dateCreated.toDate();
        ret.dateEdited = ret.dateEdited?.toDate();
        return ret;
    } else {
        return null;
    }
}

function transformPhotoFromDB(snap) {
    const ret = {
        ...snap.data(),
        _id: snap.id,
    };
    ret.date = ret.date.toDate();
    return ret;
}

export function subscribeToAlbum(id, callback) {
    const ref = doc(db, path(`albums/${id}`));
    return onSnapshot(ref, (snap) => {
        const album = snap.exists ? transformAlbumFromDB(snap) : null;
        callback(album);
    });
}

export function subscribeToAlbums(callback) {
    const ref = query(
        collection(db, path("albums")),
        orderBy("dateCreated", "desc")
    );
    return onSnapshot(ref, (querySnap) => {
        const albums = querySnap.docs.map(transformAlbumFromDB);
        callback(albums);
    });
}

export function subscribeToAlbumPhotos(id, callback) {
    const ref = query(
        collection(db, path(`albums/${id}/photos`)),
        orderBy("date")
    );
    return onSnapshot(ref, (querySnap) => {
        const photos = querySnap.docs.map(transformPhotoFromDB);
        callback(photos);
    });
}

export async function createNewAlbum() {
    const ref = await addDoc(collection(db, path("albums")), {
        name: "",
        dateCreated: new Date(),
        photoCount: 0,
    });
    return ref.id;
}

export async function getAlbum(id) {
    const ref = doc(db, path(`albums/${id}`));
    const snap = await getDoc(ref);
    if (!snap.exists) {
        return null;
    }
    return transformAlbumFromDB(snap);
}

export async function updateAlbum(id, updates) {
    const ref = doc(db, path(`albums/${id}`));
    await updateDoc(ref, {
        ...updates,
        dateEdited: new Date(),
    });
}

export async function deleteAlbum(id) {
    const ref = doc(db, path(`albums/${id}`));
    await deleteDoc(ref);
}

export async function updatePhoto(albumID, photoID, updates) {
    const ref = doc(db, path(`albums/${albumID}/photos/${photoID}`));
    await updateDoc(ref, updates);
}

export async function deletePhoto(albumID, photoID) {
    const ref = doc(db, path(`albums/${albumID}/photos/${photoID}`));
    await deleteDoc(ref);
}

// emailCount

export function subscribeToSentEmailCount(callback) {
    const ref = doc(db, "/sendgrid/emailsSent");
    return onSnapshot(ref, (snap) => {
        const count = snap.data().today;
        callback(count);
    });
}
