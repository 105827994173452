import { useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { EventForm } from "./EventForm";
import { FirebaseUploadAdapter } from "../../../components";
import { useOnTypingStop } from "../../../hooks";

export function PostForm({
    post,
    postID,
    onChange = () => {},
    onUpdated = () => {},
    onTextFieldFocus = () => {},
    onTextFieldBlur = () => {},
}) {
    const timeout = useRef(null);

    const titleTypingStopProps = useOnTypingStop((e) => {
        onUpdated({ title: e.target.value });
    });

    const handleCKEditorChange = (_, editor) => {
        const update = { content: editor.getData() };
        onChange(update);
        // see if we have an existing timeout set, if so clear it
        if (timeout.current !== null) {
            clearTimeout(timeout.current);
        }
        // set new timeout that will save our work if we don't type for a bit
        timeout.current = setTimeout(async () => {
            await onUpdated(update);
            timeout.current = null;
        }, 3_000);
    };

    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
        let update = { [name]: value };
        if (name === "type") {
            if (value === "event") {
                update = {
                    ...update,
                    eventDetails: {
                        location: "7505 Fairport Road, Dallas, TX 75217",
                        start: null,
                        end: null,
                    },
                };
            } else {
                update = { ...update, eventDetails: null };
            }
        }
        onChange(update);
        if (type === "radio") {
            onUpdated(update);
        }
    };

    const handleEventFormChange = (change) => {
        const update = { eventDetails: { ...post.eventDetails, ...change } };
        onChange(update);
    };

    const handleEventFormUpdated = (change) => {
        const update = { eventDetails: { ...post.eventDetails, ...change } };
        onUpdated(update);
    };

    const handleFocus = (field) => onTextFieldFocus(field);
    const handleBlur = (field) => onTextFieldBlur(field);

    return (
        <div className={"form"}>
            <div className="post-types">
                Post Type:
                <div className="radio-group">
                    <input
                        type="radio"
                        name="type"
                        value="update"
                        id="update"
                        onChange={handleInputChange}
                        checked={post.type === "update"}
                    />
                    <label htmlFor="update">Update</label>
                </div>
                <div className="radio-group">
                    <input
                        type="radio"
                        name="type"
                        value="event"
                        id="event"
                        onChange={handleInputChange}
                        checked={post.type === "event"}
                    />
                    <label htmlFor="event">Event</label>
                </div>
            </div>
            {post.type === "event" && (
                <EventForm
                    post={post}
                    onChange={handleEventFormChange}
                    onUpdated={handleEventFormUpdated}
                    onLocationFocus={() => handleFocus("location")}
                    onLocationBlur={() => handleBlur("location")}
                />
            )}
            <input
                type="text"
                name="title"
                placeholder="Enter title here."
                onChange={handleInputChange}
                onFocus={() => handleFocus("title")}
                onBlur={() => handleBlur("title")}
                {...titleTypingStopProps}
                value={post.title}
                style={{ margin: ".5rem auto" }}
            />
            <CKEditor
                editor={ClassicEditor}
                data={post ? post.content : ""}
                onReady={(editor) => {
                    editor.plugins.get("FileRepository").createUploadAdapter = (
                        loader
                    ) => {
                        // Configure the URL to the upload script in your back-end here!
                        return new FirebaseUploadAdapter(loader, postID);
                    };
                }}
                onChange={handleCKEditorChange}
                onFocus={() => handleFocus("content")}
                onBlur={() => handleBlur("content")}
            />
        </div>
    );
}
