import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BarLoader } from "react-spinners";
import { addBooklet } from "../../firebase/db";
import { deleteFile, uploadFile } from "../../firebase/storage";
import { toast } from "react-toastify";
import "./Booklet.scss";
import { FileUpload } from "../../components";

const isPdf = (filename) => {
    const extension = filename.split(".").pop();
    return extension.toLowerCase() === "pdf";
};

export function BookletUpload() {
    const navigate = useNavigate();

    const [isUploading, setIsUploading] = useState(false);
    const [feedback, setFeedback] = useState("");

    const [title, setTitle] = useState("");
    const [uploadResult, setUploadResult] = useState(null);

    const handleTitleChange = (e) => setTitle(e.target.value);

    const handleFileChange = async (files) => {
        const file = files[0];
        if (!file.name) {
            return;
        } else if (!isPdf(file.name)) {
            setFeedback(
                `${file.name} is not a PDF. Only files with the ".pdf" extension are allowed.`
            );
            return;
        }
        setIsUploading(true);
        try {
            const promises = [];
            if (uploadResult) {
                promises.push(deleteFile(uploadResult.ref));
            }
            promises.push(
                uploadFile(`pdfs/${file.name}`, file, {
                    contentType: "application/pdf",
                })
            );
            const results = await Promise.all(promises);
            setUploadResult(results.pop());
        } catch (e) {
            setFeedback(e.message);
        } finally {
            setIsUploading(false);
        }
    };

    const handleSubmit = async () => {
        if (!title || !uploadResult) {
            setFeedback("Fill out all fields.");
            return;
        }
        try {
            await addBooklet(
                title,
                new Date(),
                uploadResult.url,
                uploadResult.ref.fullPath
            );
            toast(`Uploaded "${title}".`, { type: "success" });
            navigate("/booklets");
        } catch (error) {
            setFeedback(error.message);
        }
    };

    return (
        <div className="content-wrap booklet-upload">
            <div className="pg-title">Upload a PDF Booklet</div>
            <p className="feedback">{feedback}</p>
            <div className="form">
                <input
                    type="text"
                    value={title}
                    placeholder="Enter PDF title here."
                    onChange={handleTitleChange}
                />
                <BarLoader color="#d9d59a" loading={isUploading} />
                {uploadResult && (
                    <p>
                        <a
                            href={uploadResult.url}
                            target="_blank"
                            rel="noreferrer"
                        >
                            View {uploadResult.ref.name} here.
                        </a>
                    </p>
                )}
                <div className="buttons">
                    <FileUpload onChange={handleFileChange}>
                        {uploadResult ? "Change File" : "Upload File"}
                    </FileUpload>
                    <button
                        disabled={isUploading || !title || !uploadResult}
                        onClick={handleSubmit}
                    >
                        Submit Booklet
                    </button>
                </div>
            </div>
        </div>
    );
}
