import { Link } from "react-router-dom";

export function Unverified() {
    return (
        <div className="content-wrap home">
            <h3>Error</h3>
            <p>
                It appears your account has not been verified by an admin yet.
            </p>
            <p>Please come back and try logging in again later.</p>
            <p>
                <Link to="/">Go back home.</Link>
            </p>
        </div>
    );
}
