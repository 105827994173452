import { uploadFile } from "../firebase";

export class FirebaseUploadAdapter {
    constructor(loader, postID = null) {
        this.loader = loader;
        this.reject = null;
        this.postID = postID;
    }

    async upload() {
        const uploadPath = "posts" + (this.postID ? `/${this.postID}` : "");
        const file = await this.loader.file;
        // upload to storage
        return new Promise((resolve, reject) => {
            this.reject = reject;
            uploadFile(
                `${uploadPath}/${file.name}`,
                file,
                { contentType: file.type },
                { overwriteExisting: false }
            )
                .then((result) => {
                    this.reject = null;
                    resolve({ default: result.url });
                })
                .catch((reason) => reject(reason));
        });
    }

    abort() {
        if (this.reject) {
            this.reject("Aborted upload task.");
        }
    }
}
