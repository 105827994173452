import date from "date-and-time";
import { useOnTypingStop } from "../../../hooks";

function formatDate(dt) {
    return date.format(dt, "YYYY-MM-DDTHH:mm");
}

export function EventForm({
    post: { eventDetails },
    onChange = () => {},
    onUpdated = () => {},
    onLocationFocus = () => {},
    onLocationBlur = () => {},
}) {
    const onTypingStopProps = useOnTypingStop((e) => {
        onUpdated({ location: e.target.value });
    });

    const handleDateTimeChange = (e) => {
        const { name, value } = e.target;
        const { start, end } = eventDetails;
        if (name === "start") {
            // change end as well
            const newStart = new Date(value);
            const newEnd = new Date(newStart);
            let offset = 0;
            if (start && end) {
                offset = end - start;
            } else {
                offset = 3_600_000; // 1 hr in ms
            }
            newEnd.setTime(newEnd.getTime() + offset);
            const update = {
                start: newStart,
                end: newEnd,
            };
            onChange(update);
            onUpdated(update);
        } else {
            const update = { end: new Date(value) };
            onChange(update);
            onUpdated(update);
        }
    };

    const handleLocationChange = (e) => {
        onChange({ location: e.target.value });
    };

    if (!eventDetails) {
        return null;
    }

    const { start, end, location } = eventDetails;

    return (
        <div className="event event-info">
            <div className="input-group">
                <label htmlFor="start-datetime">Start Datetime:</label>
                <input
                    type="datetime-local"
                    name="start"
                    id="start-datetime"
                    onChange={handleDateTimeChange}
                    value={start instanceof Date ? formatDate(start) : ""}
                />
            </div>
            <div className="input-group">
                <label htmlFor="end-datetime">End Datetime: </label>
                <input
                    type="datetime-local"
                    name="end"
                    id="end-datetime"
                    onChange={handleDateTimeChange}
                    value={end instanceof Date ? formatDate(end) : ""}
                />
            </div>
            <div className="input-group">
                <label htmlFor="location">Address: </label>
                <input
                    type="text"
                    name="location"
                    id="location"
                    placeholder="Enter Address Here"
                    value={location}
                    onChange={handleLocationChange}
                    {...onTypingStopProps}
                    onFocus={onLocationFocus}
                    onBlur={onLocationBlur}
                />
            </div>
        </div>
    );
}
