import { useState, useEffect } from "react";
import ReactModal, { setAppElement } from "react-modal";
import { getEmails } from "../../../firebase";
import "../Post.scss";

const RADIO_STATES = { all: "all", none: "none", select: "select" };
const RADIO_BTNS = [
    {
        value: RADIO_STATES.all,
        id: "modal-radio-all",
        label: "Send to All",
    },
    {
        value: RADIO_STATES.none,
        id: "modal-radio-none",
        label: "Send to None",
    },
    {
        value: RADIO_STATES.select,
        id: "modal-radio-select",
        label: "Send to Selected",
    },
];

export function PostEmailModal({
    modalReactState: [open, setOpen],
    emailsSent,
    emailQuota,
    initialRecipients,
    onEmailRecipientsSet = () => {},
}) {
    const [emailMap, setEmailMap] = useState({});
    const [emailToName, setEmailToName] = useState({});
    const [allEmails, setAllEmails] = useState([]);
    const [radioState, setRadioState] = useState(RADIO_STATES.all);
    const [selectCount, setSelectCount] = useState(0);

    useEffect(() => {
        setAppElement("#root");
        getEmails().then((emails) => {
            const updatedEmailMap = {};
            const updatedEmailToName = {};
            let recipientsToSet = null;
            if (initialRecipients instanceof Array) {
                setRadioState(RADIO_STATES.select);
                recipientsToSet = new Set(
                    initialRecipients.map((recipient) => recipient.email)
                );
            }
            for (const { email, name } of emails) {
                if (recipientsToSet) {
                    updatedEmailMap[email] = recipientsToSet.has(email);
                } else {
                    updatedEmailMap[email] = true;
                }
                if (name) {
                    updatedEmailToName[email] = name;
                }
            }
            setEmailMap(updatedEmailMap);
            setEmailToName(updatedEmailToName);
            setAllEmails(emails);
            setSelectCount(emails.length);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRadioChange = (e) => {
        const newState = e.target.value;
        setRadioState(newState);
        if (newState !== RADIO_STATES.select) {
            const isSelectAll = newState === RADIO_STATES.all;
            const updatedAllEmails = {};
            for (const email in emailMap) {
                updatedAllEmails[email] = isSelectAll;
            }
            setEmailMap(updatedAllEmails);
            setSelectCount(isSelectAll ? allEmails.length : 0);
        }
    };

    const handleClose = () => {
        const recipients = allEmails.filter(
            ({ email }) => emailMap[email] === true
        );
        onEmailRecipientsSet(recipients);
        setOpen(false);
    };

    const handleCheck = (email, isCurrentlyChecked) => {
        const willBeChecked = !isCurrentlyChecked;
        setEmailMap((prev) => ({
            ...prev,
            [email]: willBeChecked,
        }));
        setSelectCount((prev) => {
            const inc = willBeChecked ? 1 : -1;
            return prev + inc;
        });
    };

    return (
        <ReactModal isOpen={open} onRequestClose={handleClose}>
            <div className="emailModal">
                <h6>Email Recipients</h6>
                <p style={{ textAlign: "center" }}>
                    Emails Sent Today: <strong>{emailsSent}</strong> /{" "}
                    {emailQuota}
                </p>
                <div className="modalRadioButtons">
                    {RADIO_BTNS.map(({ value, id, label }, i) => (
                        <div className="modalRadioGroup" key={i}>
                            <label htmlFor={id}>
                                {label}{" "}
                                {value === RADIO_STATES.select && (
                                    <span
                                        className={
                                            selectCount + emailsSent >
                                            emailQuota
                                                ? "overQuota"
                                                : ""
                                        }
                                    >
                                        ({selectCount})
                                    </span>
                                )}
                            </label>
                            <input
                                type="radio"
                                name="modalRadio"
                                value={value}
                                id={id}
                                checked={radioState === value}
                                onChange={handleRadioChange}
                            />
                        </div>
                    ))}
                </div>
                <ul className="modalCheckboxes">
                    {Object.entries(emailMap).map(([email, checked], i) => {
                        const id = `checkbox-for-${email}`;
                        const isDisabled = radioState !== RADIO_STATES.select;
                        return (
                            <li
                                className={
                                    "modalCheckboxGroup" +
                                    (isDisabled ? " disabled" : "")
                                }
                                key={i}
                            >
                                <input
                                    type="checkbox"
                                    name="emailRecipients"
                                    value={email}
                                    id={id}
                                    checked={checked}
                                    onChange={() => handleCheck(email, checked)}
                                    disabled={isDisabled}
                                />
                                <label htmlFor={id}>
                                    {email}{" "}
                                    {emailToName[email] && (
                                        <i>({emailToName[email]})</i>
                                    )}
                                </label>
                            </li>
                        );
                    })}
                </ul>
                <button className="closeBtn" onClick={handleClose}>
                    Done
                </button>
            </div>
        </ReactModal>
    );
}
