import React, { useState, useEffect, Fragment } from "react";
import {
    subscribeToEmails,
    addEmails,
    deleteEmail,
    deleteEmails,
    setNameForEmail,
} from "../../firebase/db";
import { toast } from "react-toastify";
import { IconButton, SearchBar } from "../../components";
import "./Email.scss";

const EMAIL_REGEX =
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export function Email() {
    const [emails, setEmails] = useState([]);
    const [emailsToAdd, setEmailsToAdd] = useState("");
    const [emailsToDelete, setEmailsToDelete] = useState({});
    const [selectAll, setSelectAll] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [feedback, setFeedback] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [queryResults, setQueryResults] = useState([]);

    useEffect(() => {
        const unsub = subscribeToEmails((emails) => setEmails(emails));
        return unsub;
    }, []);

    const handleSubmit = async () => {
        const split = emailsToAdd.split(",").map((email) => email.trim());
        await addEmails(split);
        setEmailsToAdd("");
        toast(`Added ${split.length} emails.`, { type: "success" });
    };

    const toggleSelectAll = () => {
        setEmailsToDelete({});
        setSelectAll(!selectAll);
    };

    const unselectAll = () => {
        setEmailsToDelete({});
        setSelectAll(false);
    };

    const getSelected = () => {
        if (selectAll) {
            return emails;
        } else {
            return Object.keys(emailsToDelete);
        }
    };

    const handleCopy = () => {
        const selected = getSelected();
        let stringified;
        if (selected.length > 1) {
            stringified = selected.reduce((prev, curr) => `${prev},${curr}`);
        } else {
            stringified = selected[0];
        }
        navigator.clipboard.writeText([stringified]);
        unselectAll();
    };

    const handleBatchDelete = () => {
        deleteEmails(getSelected());
        unselectAll();
    };

    const handleTextChange = (e) => {
        const emails = e.target.value;
        setEmailsToAdd(emails);
        if (emails.length === 0) {
            setDisableSubmit(true);
            setFeedback("");
        } else {
            const failsRegex = emails
                .split(",")
                .map((email) => {
                    email = email.trim();
                    return EMAIL_REGEX.test(email);
                })
                .includes(false);
            setDisableSubmit(failsRegex);
            if (failsRegex) {
                setFeedback("One or more emails are invalid.");
            } else {
                setFeedback("");
            }
        }
    };

    const handleSearchChange = (text) => {
        setSearchQuery(text);
        if (text.length === 0) {
            setQueryResults([]);
        } else {
            setQueryResults(emails.filter(({ email }) => email.includes(text)));
        }
    };

    const showExtraOperations =
        selectAll || Object.values(emailsToDelete).includes(true);

    const emailsToDisplay = searchQuery.length > 0 ? queryResults : emails;

    return (
        <div className="content-wrap emails">
            {feedback && <p className="feedback">{feedback}</p>}
            <div className="form">
                <input
                    type="text"
                    placeholder="Enter emails to add (sep by comma)."
                    value={emailsToAdd}
                    onChange={handleTextChange}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            handleSubmit();
                        }
                    }}
                />
                {showExtraOperations && (
                    <Fragment>
                        <button
                            className="operationBtn extra"
                            onClick={handleBatchDelete}
                        >
                            Delete All
                        </button>
                        <button
                            className="operationBtn extra"
                            onClick={handleCopy}
                        >
                            Copy All
                        </button>
                    </Fragment>
                )}
                <button
                    className="operationBtn"
                    onClick={handleSubmit}
                    disabled={disableSubmit}
                >
                    Submit
                </button>
                <button className="operationBtn" onClick={toggleSelectAll}>
                    {selectAll ? "Unselect All" : "Select All"}
                </button>
            </div>

            <div className="email-search-con">
                <SearchBar
                    className="email-searchbar"
                    value={searchQuery}
                    onTextChange={handleSearchChange}
                    placeholder="Search for a specific email."
                />
                <div className="email-count">
                    Showing {emailsToDisplay.length} email
                    {emailsToDisplay.length !== 1 ? "s" : ""}.
                </div>
            </div>

            <div className="email-list">
                {emailsToDisplay.map(({ email, name }, i) => {
                    const selected = emailsToDelete[email] || selectAll;
                    return (
                        <div
                            className={"email" + (selected ? " selected" : "")}
                            key={i}
                        >
                            <div className="label">
                                <div className="label__email">{email}</div>
                                <div className="label__name">{name}</div>
                            </div>
                            <input
                                type="checkbox"
                                value={selected}
                                checked={selected}
                                onChange={() => {
                                    setEmailsToDelete({
                                        ...emailsToDelete,
                                        [email]: !selected,
                                    });
                                }}
                            />
                            <IconButton
                                icon="pencil"
                                size={16}
                                onClick={() => {
                                    const newName = prompt(
                                        "Enter a name for the recipient."
                                    );
                                    if (newName) {
                                        setNameForEmail(email, newName);
                                    }
                                }}
                                caption={{ text: "Add Name" }}
                            />
                            <IconButton
                                icon="trash"
                                size={16}
                                onClick={() => deleteEmail(email)}
                                caption={{ text: "Delete" }}
                                style={{ marginLeft: 8 }}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
