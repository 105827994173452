import { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { useOnTypingStop } from "../../../hooks";
import { updatePhoto, deletePhoto, getPhoto } from "../../../firebase";
import "./Modals.scss";

export function PhotoEditModal({ albumID, modalState: [photo, setPhoto] }) {
    const [editDate, setEditDate] = useState(null);

    useEffect(() => {
        ReactModal.setAppElement("#root");
    }, []);

    useEffect(() => {
        if (photo) {
            // setPhoto()
        }
    }, [photo]);

    const inputKeyHandlerProps = useOnTypingStop(async (e) => {
        if (photo) {
            const { _id: photoID } = photo;
            await updatePhoto(albumID, photoID, { caption: e.target.value });
            setEditDate(new Date());
        }
    });

    const handleClose = () => {
        setPhoto(null);
    };

    let body = null;

    if (photo !== null) {
        const { _id: photoID, url, caption, storagePath } = photo;

        const handleSave = async () => {
            if (editDate) {
                await updatePhoto(albumID, photoID, { caption });
            }
            handleClose();
        };

        const handleDelete = async () => {
            await deletePhoto(albumID, photoID);
            handleClose();
        };

        body = (
            <div className="photoModal">
                <h6>Photo: {storagePath}</h6>
                <img src={url} alt={caption || "Upload for PMC"} />
                {editDate && (
                    <div className="photoModal__editTimestamp">
                        Edited{" "}
                        {editDate.toLocaleString("en", {
                            dateStyle: "short",
                            timeStyle: "medium",
                        })}
                    </div>
                )}
                <div className="photoModal__operations">
                    <input
                        placeholder="Enter Caption Here"
                        type="text"
                        value={caption}
                        onChange={(e) =>
                            setPhoto((prev) => ({
                                ...prev,
                                caption: e.target.value,
                            }))
                        }
                        {...inputKeyHandlerProps}
                    />
                    <button onClick={handleSave}>Save and Close</button>
                    <button onClick={handleDelete}>Delete</button>
                </div>
            </div>
        );
    }

    return (
        <ReactModal isOpen={photo !== null} onRequestClose={handleClose}>
            {body}
        </ReactModal>
    );
}
