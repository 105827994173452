const FIELD_NAMES = {
    title: "title",
    content: "post content",
    start: "start datetime",
    end: "end datetime",
    location: "location",
};

export function verifyPost(post) {
    // check if title and content are filled
    for (const key of ["title", "content"]) {
        if (!post[key]) {
            return `The ${FIELD_NAMES[key]} field cannot be empty.`;
        }
    }
    if (post.type === "event") {
        // check if fields are filled
        for (const key of ["start", "end", "location"]) {
            if (!post.eventDetails[key]) {
                return `The ${FIELD_NAMES[key]} field cannot be empty.`;
            }
        }
        // check if dates are valid
        const { start, end } = post.eventDetails;
        // start date must be in future
        const now = new Date();
        if (start < now) {
            return "Start datetime cannot be in the past.";
        }
        // end date must also be greater than start
        if (end < start) {
            return "End datetime must be after start datetime.";
        }
    }
    // we're good
    return null;
}
