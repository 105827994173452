import { Fragment } from "react";
import { ReactComponent as PencilFill } from "../../assets/pencil-fill.svg";
import { ReactComponent as TrashFill } from "../../assets/trash-fill.svg";
import { ReactComponent as XCircleFill } from "../../assets/x-circle-fill.svg";
import { ReactComponent as PlusSquareFill } from "../../assets/plus-square-fill.svg";
import { ReactComponent as ArrowLeft } from "../../assets/arrow-left-solid.svg";
import "./IconButton.scss";
import { Link } from "react-router-dom";

const svgDict = {
    pencil: PencilFill,
    trash: TrashFill,
    "x-circle": XCircleFill,
    "plus-square": PlusSquareFill,
    "arrow-left": ArrowLeft,
};

const DEFAULT_SIZE = 24;

function getCaptionConfig(caption) {
    if (typeof caption === "string") {
        return { text: caption, direction: "column" };
    }
    return { direction: "column", ...caption };
}

export function IconButton(props) {
    const { icon, size, className, link, caption = "", ...rest } = props;
    const IconSVG = svgDict[icon];
    if (!IconSVG) {
        return null;
    }
    let svgSize = size || DEFAULT_SIZE;
    const { text, direction } = getCaptionConfig(caption);
    const body = (
        <Fragment>
            <IconSVG
                style={{
                    width: svgSize,
                    height: svgSize,
                }}
            />
            {text && <span className="iconButtonCaption">{text}</span>}
        </Fragment>
    );
    const propsToPass = {
        className:
            `iconButton caption_${direction}` +
            (className ? " " + className : ""),
        ...rest,
    };
    if (link && link.to) {
        return (
            <Link to={link.to} {...propsToPass}>
                {body}
            </Link>
        );
    } else if (link && link.href) {
        return (
            <a
                href={link.href}
                target="_blank"
                rel="noreferrer"
                {...propsToPass}
            >
                {body}
            </a>
        );
    } else {
        return <button {...propsToPass}>{body}</button>;
    }
}
