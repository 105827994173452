import { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { uploadFile } from "../../../firebase";
import "./Modals.scss";

export function UploadModal({ albumID, modalState: [open, setOpen] }) {
    useEffect(() => {
        ReactModal.setAppElement("#root");
    }, []);

    const [runAnimation, setRunAnimation] = useState(false);

    const animateDrop = () => {
        setRunAnimation(true);
        setTimeout(() => setRunAnimation(false), 500);
    };

    const handleClose = () => setOpen(false);

    const uploadFiles = async (files) => {
        let uploaded = 0,
            errored = 0,
            total = files.length;
        const toastID = toast(`Starting upload of ${total} files.`, {
            type: "info",
            progress: 0,
        });
        await Promise.allSettled(
            files.map((file) => {
                return uploadFile(
                    `albums/${albumID}/${file.name}`,
                    file,
                    { contentType: file.type },
                    { overwriteExisting: false }
                )
                    .then(() => {
                        uploaded += 1;
                        toast.update(toastID, {
                            render: `${
                                uploaded + errored
                            } out of ${total} done.`,
                            progress: (uploaded + errored) / total,
                            type: "info",
                        });
                    })
                    .catch(() => {
                        errored += 1;
                        toast.update(toastID, {
                            render: `Something went wrong. ${
                                uploaded + errored
                            } out of ${total} done.`,
                            progress: (uploaded + errored) / total,
                            type: "error",
                        });
                    });
            })
        );
        toast.update(toastID, {
            render: `${uploaded} out of ${total} files successfully uploaded. Please be patient while they process in the background!`,
            progress: 1,
            type: "success",
        });
    };

    const {
        fileRejections,
        getRootProps,
        getInputProps,
        open: openFilePrompt,
    } = useDropzone({
        onDrop: (accepted, rejected) => {
            animateDrop();
            const numRejected = rejected.length;
            if (numRejected > 0) {
                toast(
                    `Rejected ${rejected.length} file${
                        numRejected !== 1 ? "s" : ""
                    }. See reasons below.`,
                    {
                        type: "error",
                    }
                );
            }
            if (accepted.length > 0) {
                // start uploading
                uploadFiles(accepted);
            }
        },
        accept: {
            "image/png": [".png"],
            "image/jpeg": [".jpg", ".jpeg"],
        },
    });

    return (
        <ReactModal isOpen={open} onRequestClose={handleClose}>
            <div className="uploadModal">
                <h6>Upload Images</h6>
                <div
                    {...getRootProps({
                        className:
                            "dropzone" + (runAnimation ? " animated" : ""),
                    })}
                >
                    <input {...getInputProps()} />
                    <p>
                        Drag and drop files here or click the button below to
                        select files to upload.
                    </p>
                    <button onClick={openFilePrompt}>Upload Files</button>
                </div>
                <p>
                    Note: Successfully uploaded files won't appear immediately
                    on the album page due to processing time needed on our
                    back-end. Please be patient!
                </p>
                <button id="doneBtn" onClick={handleClose}>
                    Done
                </button>
                {fileRejections.length > 0 && (
                    <ul className="uploadModal__rejections">
                        <div id="rejectionHeader">Rejections</div>
                        {fileRejections.map(({ file, errors }, i) => {
                            return (
                                <li className="rejection">
                                    <div className="rejection__filename">
                                        {file.name}
                                    </div>
                                    <div className="rejection__reasons">
                                        Reason:{" "}
                                        {errors.map((err, i) => {
                                            return `${i !== 0 ? ", " : ""}${
                                                err.message
                                            }`;
                                        })}
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>
        </ReactModal>
    );
}
