import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { createNewPost } from "../../firebase";

export function PostCreate() {
    const navigate = useNavigate();

    useEffect(() => {
        createNewPost().then((id) => {
            navigate(`/posts/${id}/edit`);
        });
    }, [navigate]);

    return (
        <div style={{ margin: "auto", textAlign: "center" }}>
            <ClipLoader loading color="#c0ba62" size={48} />
            <p>
                <strong>Creating new post...</strong>
            </p>
        </div>
    );
}
