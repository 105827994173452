import React, { useState, useEffect } from "react";
import { IconButton, SearchBar } from "../../components";
import { Album } from "./subcomponents/Album";
import { subscribeToAlbums } from "../../firebase";
import "./Gallery.scss";

export function Gallery() {
    const [albums, setAlbums] = useState(null);

    useEffect(() => {
        // get all albums
        return subscribeToAlbums(setAlbums);
    }, []);

    return (
        <div className="content-wrap gallery">
            <div className="gallerySearch">
                <SearchBar placeholder="Search for an album by name." />
            </div>
            <div className="galleryOps">
                <IconButton
                    icon="plus-square"
                    caption={{ text: "New Album", direction: "row" }}
                    link={{ to: "/album/new" }}
                    size={20}
                />
            </div>
            <ul className="galleryAlbums">
                {albums instanceof Array &&
                    albums.map((album, i) => <Album {...album} key={i} />)}
            </ul>
        </div>
    );
}
