import React, { useState, useEffect, useRef, Fragment } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { BarLoader } from "react-spinners";
import {
    updateDraft,
    subscribeToPostDraft,
    subscribeToSentEmailCount,
    commitDraftedChanges,
} from "../../firebase";
import { IconButton } from "../../components";
import {
    PostForm,
    PostEmailModal,
    MediaList,
    PostFileUpload,
    verifyPost,
} from "./subcomponents";
import "./Post.scss";

const ACCEPTED_TYPES = ["image/jpeg", "image/png", "application/pdf"];
const EMAIL_QUOTA = 100;

const SUBMIT_STATES = {
    NOT_SUBMITTED: "notSubmitted",
    SUBMITTING: "submitting",
    SUBMITTED: "submitted",
};

export function PostEdit() {
    const { id: postID } = useParams();
    const navigate = useNavigate();

    // the post
    const [post, setPost] = useState(null);
    // for publishing
    const [emailsSentToday, setEmailsSentToday] = useState(0);
    // component states
    const [feedback, setFeedback] = useState("");
    const [submitState, setSubmitState] = useState(SUBMIT_STATES.NOT_SUBMITTED);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [hasSaved, setHasSaved] = useState(false);

    const focused = useRef(null);

    const hasNotBeenPublished = post && post.date.published === null;

    const handlePublish = async () => {
        // see if inputs are filled
        const feedback = verifyPost(post);
        if (feedback) {
            setFeedback(feedback);
            return;
        }
        // check if they've set their email recipients
        if (post && !post.date.published) {
            const { emailRecipients } = post;
            if (emailRecipients === null) {
                setFeedback(
                    "Make sure to configure the recipients to send this to."
                );
                return;
            }
            const numToRemove =
                emailRecipients.length + emailsSentToday - EMAIL_QUOTA;
            if (numToRemove > 0) {
                setFeedback(
                    `You are exceeding sendgrid's daily email quota by ${numToRemove} recipient(s). (If you keep running into this issue, contact Larry Win to talk about upgrading our SendGrid email plan.)`
                );
                return;
            }
        }
        setFeedback("");
        // we're good to send
        // shallow copy
        const postToSend = { ...post };
        postToSend.date = new Date();
        if (postToSend.type === "update") {
            // delete event info
            delete postToSend.eventDetails;
        }
        // set publish date to start backend function call
        setSubmitState(SUBMIT_STATES.SUBMITTING);
        try {
            await updateDraft(postID, {
                "date.published": new Date(),
                visible: true,
            });
            await commitDraftedChanges(postID);
            setSubmitState(SUBMIT_STATES.SUBMITTED);
        } catch (error) {
            setSubmitState(SUBMIT_STATES.NOT_SUBMITTED);
            setFeedback(`Error: ${error.message}`);
        }
    };

    const handleSave = async (commitChanges = false, redirect = true) => {
        // save to DB
        setSubmitState(SUBMIT_STATES.SUBMITTING);
        try {
            await updateDraft(postID, post);
            if (commitChanges === true || post.date.published === null) {
                await commitDraftedChanges(postID);
            }
            if (redirect === true) {
                navigate("/posts");
            } else {
                setSubmitState(SUBMIT_STATES.NOT_SUBMITTED);
            }
        } catch (error) {
            setSubmitState(SUBMIT_STATES.NOT_SUBMITTED);
            setFeedback(`Error: ${error.message}`);
        }
    };

    const savePostChange = async (change) => {
        await updateDraft(postID, change);
        setHasSaved(true);
    };

    const handleTextFieldFocus = (field) => (focused.current = field);
    const handleTextFieldBlur = () => (focused.current = null);

    const handlePostChange = (change) => {
        setPost((prev) => ({
            ...prev,
            ...change,
            committed: false,
        }));
        setHasSaved(false);
    };

    const handleFileDeleted = (i) => {
        setPost((prev) => {
            const newArray = [...prev.media];
            newArray.splice(i, 1);
            return {
                ...prev,
                media: newArray,
            };
        });
    };

    const handleFileUploaded = (media) => {
        savePostChange({
            media: [...post.media, media],
        });
    };

    const handleEmailRecipientsSet = async (recipients) => {
        const update = { emailRecipients: recipients };
        handlePostChange(update);
        await savePostChange(update);
    };

    const toggleVisibility = async () => {
        await savePostChange({
            visible: !post.visible,
        });
    };

    useEffect(() => {
        return subscribeToPostDraft(
            postID,
            (post) => {
                if (post) {
                    delete post._id;
                    const currFocused = focused.current;
                    if (currFocused !== null) {
                        if (currFocused === "location" && post.eventDetails) {
                            delete post.eventDetails.location;
                        } else {
                            delete post[currFocused];
                        }
                    }
                    setPost((prev) => ({ ...prev, ...post }));
                } else {
                    navigate("/albums");
                }
            },
            false
        );
    }, [postID, navigate]);

    useEffect(() => {
        return subscribeToSentEmailCount(setEmailsSentToday);
    }, [post]);

    if (!post) {
        return null;
    }

    const showForm = submitState !== SUBMIT_STATES.SUBMITTED && !modalIsOpen;

    console.log(post.visible);

    return (
        <div className="content-wrap post-create">
            {hasNotBeenPublished && (
                <PostEmailModal
                    emailsSent={emailsSentToday}
                    emailQuota={EMAIL_QUOTA}
                    modalReactState={[modalIsOpen, setModalIsOpen]}
                    initialRecipients={post.emailRecipients}
                    onEmailRecipientsSet={handleEmailRecipientsSet}
                />
            )}
            <IconButton
                icon="arrow-left"
                link={{ to: "/posts" }}
                caption={{ text: "Back", direction: "row" }}
                size={16}
                style={{ display: "inline-flex" }}
            />
            <div className="pg-title">{postID ? "Edit" : "Create"} a Post</div>
            <BarLoader
                color="#d9d59a"
                loading={submitState === SUBMIT_STATES.SUBMITTING}
            />
            <p className="feedback">{feedback}</p>
            {post.date.edited && hasSaved && (
                <p style={{ fontSize: ".75rem" }}>
                    Saved at{" "}
                    {post.date.edited.toLocaleString("en", {
                        dateStyle: "long",
                        timeStyle: "medium",
                    })}
                </p>
            )}
            {submitState === SUBMIT_STATES.SUBMITTED && (
                <p>
                    Your post has been submitted!. Go to{" "}
                    <Link to="/posts">all posts</Link> to find and edit it.
                </p>
            )}
            {showForm && (
                <Fragment>
                    <PostForm
                        post={post}
                        postID={postID}
                        onChange={handlePostChange}
                        onUpdated={savePostChange}
                        onTextFieldFocus={handleTextFieldFocus}
                        onTextFieldBlur={handleTextFieldBlur}
                    />
                    {post.media.length > 0 && (
                        <MediaList
                            media={post.media}
                            onDeleted={handleFileDeleted}
                        />
                    )}
                    <div className="postCreate__buttons">
                        <PostFileUpload
                            onError={(err) => setFeedback(err)}
                            onFileUploaded={handleFileUploaded}
                            acceptedTypes={ACCEPTED_TYPES}
                            postID={postID}
                        />
                        <button onClick={handleSave}>Save Post and Exit</button>
                        {hasNotBeenPublished ? (
                            <Fragment>
                                <button
                                    onClick={() => setModalIsOpen(!modalIsOpen)}
                                >
                                    {post.emailRecipients !== null
                                        ? `Sending to ${post.emailRecipients.length} contacts. Click to change.`
                                        : "Configure email recipients."}
                                </button>
                                <button
                                    onClick={handlePublish}
                                    className="formBtn"
                                >
                                    Publish Post
                                </button>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <button onClick={toggleVisibility}>
                                    {post.visible ? "Hide" : "Show"} Post
                                </button>
                                {!post.committed && (
                                    <button
                                        onClick={() => handleSave(true, false)}
                                    >
                                        Publish Changes
                                    </button>
                                )}
                            </Fragment>
                        )}
                    </div>
                </Fragment>
            )}
        </div>
    );
}
