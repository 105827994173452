import { Fragment } from "react";
import { toast } from "react-toastify";
import { deleteFile } from "../../../firebase";
import { IconButton } from "../../../components";
import pdfSvg from "../../../assets/file-earmark-pdf-fill.svg";
import "./MediaList.scss";

export function MediaList({ media, onDeleted }) {
    const handleDelete = async (item, i) => {
        const path = item.storagePath;
        const name = path.split("/").pop();
        await deleteFile(path);
        toast(`File ${name} was deleted.`, {
            type: "success",
            autoClose: 2000,
        });
        onDeleted(i);
    };

    return (
        <Fragment>
            <h6 style={{ textAlign: "left" }}>Media List</h6>
            <ul className="mediaList">
                {media.map((item, i) => (
                    <li className="mediaListItem" key={i}>
                        <div style={{ paddingRight: 8, paddingLeft: 8 }}>
                            <strong>{i + 1}:</strong>
                        </div>
                        <img
                            src={item.type === "image" ? item.url : pdfSvg}
                            alt="Media Thumbnail"
                        />
                        <div className="mediaListItemInfo">
                            <strong>Storage Path:</strong> {item.storagePath}
                            <br />
                            <strong>View At:</strong>{" "}
                            <a href={item.url} target="_blank" rel="noreferrer">
                                {item.url}
                            </a>
                        </div>
                        <IconButton
                            className="mediaListDeleteBtn"
                            size={16}
                            icon="x-circle"
                            onClick={() => handleDelete(item, i)}
                        />
                    </li>
                ))}
            </ul>
        </Fragment>
    );
}
