import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Booklet.scss";
import { deleteBooklet, subscribeToBooklets } from "../../firebase/db";
import { deleteFile } from "../../firebase/storage";
import { toast } from "react-toastify";
import { IconButton } from "../../components";

export class Booklet extends Component {
    constructor() {
        super();
        this.state = {
            pdfs: [],
            unsub: () => {},
        };
    }

    componentDidMount() {
        const unsub = subscribeToBooklets((booklets) => {
            this.setState({ pdfs: booklets });
        });
        this.setState({ unsub });
    }

    async deletePdf(pdf) {
        try {
            await Promise.all([
                deleteBooklet(pdf._id),
                deleteFile(pdf.storagePath),
            ]);
            toast(`Deleted booklet "${pdf.title}".`, { type: "success" });
        } catch (error) {
            toast(`Error: ${error}`, { type: "error" });
        }
    }

    render() {
        const { pdfs } = this.state;
        return (
            <div className="content-wrap booklets">
                <Link to="/booklets/upload" className="link-button">
                    Upload PDF
                </Link>
                <div className="booklet-list">
                    {pdfs.map((pdf, i) => {
                        return (
                            <div className="booklet" key={i}>
                                <div className="info">
                                    <div className="title">{pdf.title}</div>
                                    <div className="id">ID: {pdf._id}</div>
                                    <div className="id">
                                        Storage Path: {pdf.storagePath}
                                    </div>
                                    <div className="id">
                                        Uploaded: {pdf.date.toLocaleString()}
                                    </div>
                                    <a
                                        href={pdf.url}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Click to view.
                                    </a>
                                </div>
                                <div className="operations">
                                    <IconButton
                                        onClick={() => this.deletePdf(pdf)}
                                        icon="trash"
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}
