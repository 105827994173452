import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";

export function Logout() {
    const navigate = useNavigate();

    useEffect(() => {
        signOut(auth).then(() => navigate("/"));
    }, []);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "25vh",
            }}
        >
            <ClipLoader loading size={64} color="#c0ba62" />
        </div>
    );
}
