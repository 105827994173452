import { useState, useRef } from "react";
import { toast } from "react-toastify";
import { uploadFile } from "../../../firebase";
import { FileUpload } from "../../../components";

export function PostFileUpload({
    onFileUploaded = () => {},
    onError = () => {},
    acceptedTypes = [],
    postID,
}) {
    const [uploadState, setUploadState] = useState({
        filesUploaded: 0,
        uploading: false,
    });
    const toastID = useRef(null);

    const handleFileChange = async (files) => {
        // first verify files are images or pdfs
        for (const file of files) {
            if (!acceptedTypes.includes(file.type)) {
                onError(
                    `File ${file.name} is not an accepted image or pdf. Accepted types include jpegs, pngs, and pdfs.`
                );
                return;
            }
        }
        // we good
        setUploadState({
            uploading: true,
            filesUploaded: 0,
        });
        const promises = [];
        for (const file of files) {
            const fileType = file.type === "application/pdf" ? "pdf" : "image";
            const promise = uploadFile(
                `posts/${postID}/${file.name}`,
                file,
                {
                    contentType: file.type,
                },
                { overwriteExisting: false }
            ).then((result) => {
                const filesUploaded = uploadState.filesUploaded + 1;
                toast.update(toastID.current, {
                    render: `${filesUploaded} out of ${files.length} done.`,
                    progress: filesUploaded / files.length,
                });
                setUploadState((prev) => ({
                    ...prev,
                    filesUploaded: prev.filesUploaded + 1,
                }));
                onFileUploaded({
                    type: fileType,
                    url: result.url,
                    storagePath: result.ref.fullPath,
                });
            });
            promises.push(promise);
        }
        toastID.current = toast(`Uploading ${promises.length} files...`, {
            progress: 0,
            type: "info",
        });
        await Promise.all(promises);
        setUploadState({
            filesUploaded: 0,
            uploading: false,
        });
        toast.update(toastID.current, {
            render: "Upload finished!",
            type: "success",
        });
    };
    return (
        <FileUpload onChange={handleFileChange} multiple>
            Upload Files
        </FileUpload>
    );
}
