import { useRef, useState } from "react";
import { ReactComponent as SearchIcon } from "../../assets/search.svg";
import "./SearchBar.scss";

export function SearchBar(props) {
    const ref = useRef(null);

    const [text, setText] = useState("");

    const handleContainerClick = () => {
        ref.current.focus();
    };

    const handleChange = (e) => {
        const { value } = e.target;
        setText(value);
        if (props.onTextChange) {
            props.onTextChange(value);
        }
    };

    const handleSearch = () => {
        if (props.onSearch) {
            props.onSearch(text);
        }
    };

    return (
        <div
            className={
                "searchBar" + (props.className ? " " + props.className : "")
            }
            onClick={handleContainerClick}
            style={props.style}
        >
            <button className="searchBar__btn" onClick={handleSearch}>
                <SearchIcon className="searchBar__icon" />
            </button>
            <input
                ref={ref}
                className="searchBar__input"
                type="text"
                value={props.value || text}
                onChange={handleChange}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        handleSearch();
                    }
                }}
                placeholder={props.placeholder}
            />
        </div>
    );
}
