import React, { Fragment, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ClipLoader, BarLoader } from "react-spinners";
import { Photo } from "./subcomponents/Photo";
import { PhotoEditModal } from "./subcomponents/PhotoEditModal";
import { UploadModal } from "./subcomponents/UploadModal";
import {
    updateAlbum,
    subscribeToAlbum,
    subscribeToAlbumPhotos,
    deletePhoto,
} from "../../firebase";
import { useOnTypingStop } from "../../hooks";
import "./GalleryEdit.scss";
import { toast } from "react-toastify";

export function GalleryEdit() {
    const { id } = useParams();
    const navigate = useNavigate();

    // the good stuff
    const [album, setAlbum] = useState(null);
    const [photos, setPhotos] = useState(null);

    // modal states
    const [modalPhoto, setModalPhoto] = useState(null);
    const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false);

    // for selection mode
    const [selected, setSelected] = useState(null);
    const [numSelected, setNumSelected] = useState(0);
    const inSelectionMode = selected !== null;

    // misc
    const [hasUpdated, setHasUpdated] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isPublishing, setIsPublishing] = useState(false);

    useEffect(() => {
        if (id) {
            const unsubs = [];
            unsubs.push(subscribeToAlbum(id, setAlbum));
            unsubs.push(subscribeToAlbumPhotos(id, setPhotos));
            return () => unsubs.forEach((unsub) => unsub());
        }
    }, [id]);

    const typingStopInputProps = useOnTypingStop(async (e) => {
        await updateAlbum(id, {
            name: e.target.value,
        });
        setAlbum((prev) => ({ ...prev, dateEdited: new Date() }));
        setHasUpdated(true);
    });

    if (album === null) {
        return (
            <div style={{ margin: "auto", textAlign: "center" }}>
                <ClipLoader loading color="#c0ba62" size={48} />
                <p>
                    <strong>Loading...</strong>
                </p>
            </div>
        );
    }

    const handleSave = async () => {
        if (hasUpdated) {
            setIsSaving(true);
            await updateAlbum(id, { caption: album.caption });
            setIsSaving(false);
        }
        navigate("/albums");
    };

    const handlePublish = async () => {
        setIsPublishing(true);
        await updateAlbum(id, { published: !(album.published || false) });
        setIsPublishing(false);
    };

    const handlePhotoShortPress = (photo) => {
        if (inSelectionMode) {
            setSelected((selected) => {
                const prevValue = selected[photo._id] || false;
                const toggled = !prevValue;
                const ret = {
                    ...selected,
                    [photo._id]: toggled,
                };
                // get out of selection mode if no items are to be selected
                if (!Object.values(ret).includes(true)) {
                    setNumSelected(0);
                    return null;
                } else {
                    setNumSelected((prev) => (toggled ? prev + 1 : prev - 1));
                    return ret;
                }
            });
        } else {
            setModalPhoto(photo);
        }
    };

    const handlePhotoLongPress = (photo) => {
        if (!inSelectionMode) {
            // go to selection mode by initing set denoting selected photos
            setSelected({ [photo._id]: true });
            setNumSelected(1);
        } else {
            const prevValue = selected[photo._id] || false;
            const toggled = !prevValue;
            setSelected({
                ...selected,
                [photo._id]: toggled,
            });
            setNumSelected((prev) => (toggled ? prev + 1 : prev - 1));
        }
    };

    const toggleSelectionMode = () => {
        setSelected(inSelectionMode ? null : {});
    };

    const deleteSelected = async () => {
        const selectedIDs = Object.keys(selected).filter(
            (photoID) => selected[photoID]
        );
        let deleteCount = 0;
        await Promise.allSettled(
            selectedIDs.map((photoID) => {
                return deletePhoto(id, photoID).then(() => (deleteCount += 1));
            })
        );
        toast(`Deleted ${deleteCount} photos from "${album.name}".`, {
            type: "success",
        });
        setSelected(null);
        setNumSelected(0);
    };

    const date = album.dateEdited || album.dateCreated;
    const dateDisplayString = `${
        album.dateEdited ? "Last Edited" : "Created"
    }: ${date.toLocaleString("en", {
        dateStyle: "short",
        timeStyle: "short",
    })}`;

    const albumLink = `${
        process.env.NODE_ENV === "production"
            ? "https://pmcusa.org"
            : "http://localhost:3000"
    }/gallery/album/${id}`;

    return (
        <div className="content-wrap galleryEdit">
            <PhotoEditModal
                albumID={id}
                modalState={[modalPhoto, setModalPhoto]}
            />
            <UploadModal
                albumID={id}
                modalState={[uploadModalIsOpen, setUploadModalIsOpen]}
            />
            <BarLoader
                color="#d9d59a"
                loading={isSaving}
                style={{ margin: ".5rem 0" }}
            />
            <div className="inputGroup">
                <label htmlFor="galleryEditName">Album Name</label>
                <input
                    id="galleryEditName"
                    type="text"
                    value={album.name}
                    onChange={(e) =>
                        setAlbum({ ...album, name: e.target.value })
                    }
                    {...typingStopInputProps}
                />
            </div>
            <div className="galleryEdit__editDate">{dateDisplayString}</div>
            <div className="galleryEdit__operations">
                <button onClick={() => setUploadModalIsOpen(true)}>
                    Upload Images
                </button>
                <button onClick={handleSave}>Save Changes</button>
                <button onClick={handlePublish}>
                    {isPublishing ? (
                        <span
                            style={{
                                display: "inline-flex",
                                alignItems: "center",
                            }}
                        >
                            <ClipLoader
                                loading
                                color="#c0ba62"
                                size={12}
                                style={{ paddingRight: 4 }}
                            />{" "}
                            {album.published ? "Publishing" : "Un-Publishing"}
                        </span>
                    ) : album.published ? (
                        "Un-Publish Album"
                    ) : (
                        "Publish Album"
                    )}
                </button>
                <button onClick={toggleSelectionMode}>
                    {inSelectionMode
                        ? "Clear Selected"
                        : "Enter Selection Mode"}
                </button>
                {inSelectionMode && (
                    <button
                        onClick={deleteSelected}
                        disabled={numSelected === 0}
                    >
                        Delete {numSelected}
                    </button>
                )}
            </div>
            {album.published && (
                <p>
                    View album{" "}
                    <a href={albumLink} target="_blank" rel="noreferrer">
                        here
                    </a>
                    .
                </p>
            )}
            <div className="galleryEdit__divider">
                Photos{inSelectionMode && " (click to select)"}
            </div>
            {photos instanceof Array ? (
                <div className="galleryEdit__photoGrid">
                    {photos.map((photo, i) => (
                        <Photo
                            {...photo}
                            selected={selected && selected[photo._id]}
                            onEdit={() => setModalPhoto(photo)}
                            onDelete={() => deletePhoto(id, photo._id)}
                            onShortPress={() => handlePhotoShortPress(photo)}
                            onLongPress={() => handlePhotoLongPress(photo)}
                            key={i}
                        />
                    ))}
                </div>
            ) : (
                <div style={{ margin: "auto", textAlign: "center" }}>
                    <ClipLoader loading color="#c0ba62" size={48} />
                    <p>
                        <strong>Loading Photos...</strong>
                    </p>
                </div>
            )}
        </div>
    );
}
