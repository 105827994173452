import { IconButton } from "../../../components";
import { useLongPress } from "../../../hooks";
import "./Photo.scss";

export function Photo({
    thumbnail,
    caption,
    selected,
    onEdit,
    onDelete,
    onShortPress,
    onLongPress,
}) {
    const handleEdit = (e) => {
        e.stopPropagation();
        if (onEdit) onEdit();
    };

    const handleDelete = (e) => {
        e.stopPropagation();
        if (onDelete) onDelete();
    };

    const handleShortPress = () => {
        if (onShortPress) onShortPress();
    };

    const handleLongPress = () => {
        if (onLongPress) onLongPress();
    };

    const mouseHandlers = useLongPress({
        long: handleLongPress,
        short: handleShortPress,
    });

    return (
        <div
            className={"photo" + (selected ? " selected" : "")}
            {...mouseHandlers}
        >
            <div className="photo__image">
                <img
                    src={thumbnail}
                    alt={caption || "Autogenerated thumbnail."}
                />
            </div>
            <div className="photo__operations">
                <IconButton
                    className="photoOperations__btn"
                    icon="pencil"
                    onMouseUp={handleEdit}
                    size={16}
                />
                <IconButton
                    className="photoOperations__btn"
                    icon="trash"
                    onMouseUp={handleDelete}
                    size={16}
                />
            </div>
        </div>
    );
}
