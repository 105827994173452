import { Fragment, useRef } from "react";

export function FileUpload(props) {
    const ref = useRef(null);
    const { className, onChange, onClick, multiple, ...rest } = props;
    const handleChange = (e) => {
        const files = e.target.files;
        if (typeof onChange === "function") {
            onChange(files);
        }
    };
    return (
        <Fragment>
            <input
                type="file"
                ref={ref}
                onChange={handleChange}
                multiple={multiple}
                style={{ display: "none" }}
            />
            <button
                className={"fileUpload" + (className ? " " + className : "")}
                onClick={() => {
                    ref.current.click();
                    if (onClick && typeof onClick === "function") {
                        onClick();
                    }
                }}
                {...rest}
            >
                {props.children}
            </button>
        </Fragment>
    );
}
