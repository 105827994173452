import { deleteAlbum } from "../../../firebase";
import { IconButton } from "../../../components";
import { ReactComponent as EyeIcon } from "../../../assets/eye.svg";
import { ReactComponent as EyeSlashIcon } from "../../../assets/eye-slash.svg";
import "./Album.scss";

export function Album({
    name,
    dateCreated,
    dateEdited,
    photoCount,
    _id,
    published = false,
}) {
    const dateCreatedAsString = dateCreated.toLocaleString("en", {
        dateStyle: "short",
        timeStyle: "short",
    });
    const dateEditedAsString = dateEdited?.toLocaleString("en", {
        dateStyle: "short",
        timeStyle: "short",
    });

    const handleDelete = () => deleteAlbum(_id);

    return (
        <li className="album">
            <div className="album__text">
                <div className="album__header">
                    <div className="album__name">
                        {name || <i>Untitled Album</i>}
                    </div>
                    <span className="album__publishStatus">
                        {published ? <EyeIcon /> : <EyeSlashIcon />}
                        {published ? "Published" : "Un-Published"}
                    </span>
                </div>
                <div className="album__info">
                    <div className="albumInfo__date">
                        Created <strong>{dateCreatedAsString}</strong>
                    </div>
                    {dateEdited && (
                        <div className="albumInfo__date">
                            Updated <strong>{dateEditedAsString}</strong>
                        </div>
                    )}
                    <div className="albumInfo__count">
                        Contains <strong>{photoCount}</strong> photos
                    </div>
                </div>
            </div>
            <div className="album__operations">
                <IconButton
                    link={{ to: `/album/${_id}/edit` }}
                    icon="pencil"
                    caption={{ text: "Edit Album", direction: "row" }}
                    className="album__operationsBtn"
                    size={16}
                />
                <IconButton
                    icon="trash"
                    caption={{ text: "Delete Album", direction: "row" }}
                    className="album__operationsBtn"
                    size={16}
                    onClick={handleDelete}
                />
            </div>
        </li>
    );
}
