import {
    Booklet,
    Secret,
    Email,
    BookletUpload,
    Gallery,
    GalleryEdit,
    Post,
    PostEdit,
    Home,
    Unverified,
    Logout,
    GalleryNew,
    PostCreate,
} from "./pages";
import { Navigate } from "react-router-dom";

const authedRoutes = [
    {
        path: "/",
        element: <Secret />,
        label: "Home",
        key: "authedHome",
    },
    {
        path: "/emails",
        element: <Email />,
        label: "Email List",
        key: "emails",
    },
    {
        path: "/booklets",
        element: <Booklet />,
        label: "Booklets",
        key: "booklets",
    },
    {
        path: "/booklets/upload",
        element: <BookletUpload />,
        key: "bookletUpload",
    },
    {
        path: "/albums",
        element: <Gallery />,
        label: "Albums and Photos",
        key: "album",
    },
    {
        path: "/album/new",
        element: <GalleryNew />,
        key: "albumNew",
    },
    {
        path: "/album/:id/edit",
        element: <GalleryEdit />,
        key: "albumUpload",
    },
    {
        path: "/posts",
        element: <Post />,
        label: "Events and Updates",
        key: "posts",
    },
    {
        path: "/posts/create",
        element: <PostCreate />,
        key: "postCreate",
    },
    {
        path: "/posts/:id/edit",
        element: <PostEdit />,
        key: "postEdit",
    },
    {
        path: "/logout",
        element: <Logout />,
        label: "Logout",
        key: "logout",
    },
    {
        path: "*",
        element: <Navigate to="/" />,
        key: "catchAll",
    },
];

const nonAuthedRoutes = [
    {
        path: "/",
        element: <Home />,
        key: "home",
    },
    {
        path: "/unverified",
        element: <Unverified />,
        key: "unverified",
    },
];

export const getAppRoutes = ({ authed = false, navbar = false }) => {
    let routes = authed ? authedRoutes : nonAuthedRoutes;
    if (navbar) {
        routes = routes.filter((route) => route.label !== undefined);
    }
    return routes;
};
