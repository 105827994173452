import { useState, useContext, createContext } from "react";

export const AppContext = createContext({ accountIsVerifying: false });
export const useAppContext = () => useContext(AppContext);

export const useLongPress = (callback, delay = 500) => {
    const [id, setID] = useState(0);
    const [isLong, setIsLong] = useState(false);
    return {
        onMouseDown: (e) => {
            const timeoutID = setTimeout(() => {
                setIsLong(true);
                if (typeof callback === "function") {
                    callback(e);
                } else if (
                    typeof callback === "object" &&
                    typeof callback.long === "function"
                ) {
                    callback.long(e);
                }
            }, delay);
            setID(timeoutID);
        },
        onMouseUp: (e) => {
            clearTimeout(id);
            if (
                !isLong &&
                typeof callback === "object" &&
                typeof callback.short === "function"
            ) {
                callback.short(e);
            }
            setIsLong(false);
        },
    };
};

export const useOnTypingStop = (callback, delay = 2_500) => {
    const [id, setID] = useState(0);
    return {
        onKeyUp: (e) => {
            const timeoutID = setTimeout(() => callback(e), delay);
            setID(timeoutID);
        },
        onKeyDown: () => {
            clearTimeout(id);
        },
    };
};
