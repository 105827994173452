import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { getAppRoutes } from "../../routes";
import "./Navbar.scss";

export function Navbar() {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState(null);

    const handleChange = (option) => {
        setSelected(option);
        navigate(option.path);
    };

    useEffect(() => {
        setOptions(
            getAppRoutes({ authed: true, navbar: true }).map((route) => ({
                ...route,
                value: route.path,
            }))
        );
    }, []);

    useEffect(() => {
        const initial = options.find((option) => option.path === pathname);
        setSelected(initial);
    }, [pathname, options]);

    return (
        <div className="navbar">
            <h5>Admin Panel</h5>
            <div className="mobile">
                <Select
                    value={selected}
                    onChange={handleChange}
                    options={options}
                />
            </div>
            <div className="desktop">
                {options.map((option, i) => {
                    return (
                        <div className="item" key={i}>
                            <Link to={option.path}>{option.label}</Link>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
