import { storage } from "./config";
import {
    ref,
    uploadBytes,
    getDownloadURL,
    deleteObject,
    listAll,
} from "firebase/storage";

/**
 *
 * @param {string} url
 * @returns
 */
function path(url) {
    const env = process.env.NODE_ENV || "production";
    return `${env}/${url}`;
}

async function getUniqueRef(fileRef) {
    const { items } = await listAll(fileRef.parent);
    const siblingNames = new Set(items.map((fileRef) => fileRef.fullPath));
    let split = null,
        appendStr = "",
        fullPath = fileRef.fullPath,
        appendIndex = 0,
        duplCount = 0;
    while (siblingNames.has(fullPath)) {
        if (!split) {
            split = fullPath.split(".");
            appendIndex = split.length > 1 ? split.length - 2 : 0;
            appendStr = split[appendIndex];
        }
        duplCount += 1;
        split[appendIndex] = `${appendStr}_${duplCount}`;
        fullPath = split.join(".");
    }
    if (duplCount > 0) {
        fileRef = ref(storage, fullPath);
    }
    return fileRef;
}

/**
 *
 * @param {string} bucketPath
 * @param {File} file
 * @param {*} metadata
 * @param {*} options
 * @returns
 */
export async function uploadFile(
    bucketPath,
    file,
    metadata = {},
    options = { overwriteExisting: true }
) {
    let fileRef = ref(storage, path(bucketPath));
    if (!options.overwriteExisting) {
        // see if ref exists
        fileRef = await getUniqueRef(fileRef);
    }
    const data = await file.arrayBuffer();
    const result = await uploadBytes(fileRef, data, metadata);
    const downloadUrl = await getDownloadURL(result.ref);
    return {
        ref: fileRef,
        url: downloadUrl,
    };
}

export async function overwriteFile(ref, file, metadata) {
    const data = await file.arrayBuffer();
    const result = await uploadBytes(ref, data, metadata);
    const downloadUrl = await getDownloadURL(result.ref);
    return {
        ref: ref,
        url: downloadUrl,
    };
}

export async function deleteFile(refOrPath) {
    if (typeof refOrPath === "string") {
        await deleteObject(ref(storage, refOrPath));
    } else {
        await deleteObject(ref);
    }
}
