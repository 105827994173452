import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { emailPasswordSignIn, googleSignIn } from "../../firebase";
import { PulseLoader } from "react-spinners";
import { ReactComponent as GoogleIcon } from "../../assets/google.svg";
import { useAppContext } from "../../hooks";
import "./Home.scss";

export function Home() {
    const { accountIsVerifying } = useAppContext();
    const [formData, setFormData] = useState({ email: "", password: "" });
    const [message, setMessage] = useState("");
    const [authenticating, setAuthenticating] = useState(false);

    const location = useLocation();

    useEffect(() => {
        if (location && location.state && location.state.feedback) {
            setMessage(location.state.feedback);
        }
    }, [location]);

    useEffect(() => {
        if (accountIsVerifying) {
            setMessage("Verifying account...");
        } else {
            setMessage("");
        }
    }, [accountIsVerifying]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        const { email, password } = formData;
        setAuthenticating(true);
        setMessage("Authenticating login...");
        try {
            await emailPasswordSignIn(email, password);
        } catch (error) {
            setMessage(`Error: ${error.message}`);
            setAuthenticating(false);
        }
    };

    const handleGoogleSignIn = () => googleSignIn();

    return (
        <div className="content-wrap home">
            <h3>Login</h3>
            <div className="feedback">
                <PulseLoader
                    color="#d9d59a"
                    loading={authenticating || accountIsVerifying}
                />
                <div className="message">{message}</div>
            </div>
            {!authenticating && (
                <div className="form">
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        placeholder="Enter email."
                        onChange={handleInputChange}
                    />
                    <input
                        type="password"
                        name="password"
                        value={formData.password}
                        placeholder="Enter password."
                        onChange={handleInputChange}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleSubmit();
                            }
                        }}
                    />
                    <div className="buttonContainer">
                        <button onClick={handleSubmit}>Submit</button>
                        <button
                            onClick={handleGoogleSignIn}
                            className="googleBtn"
                        >
                            <GoogleIcon /> Log in with Google
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
