import { auth, db } from "./config";
import { getDoc, doc } from "firebase/firestore";
import {
    GoogleAuthProvider,
    signInWithEmailAndPassword,
    signInWithRedirect,
    onAuthStateChanged as onAuthChanged,
} from "firebase/auth";

const GOOGLE = new GoogleAuthProvider();

export async function emailPasswordSignIn(email, password) {
    await signInWithEmailAndPassword(auth, email, password);
}

export async function googleSignIn() {
    await signInWithRedirect(auth, GOOGLE);
}

export async function userIsVerified(user) {
    const uid = user.uid;
    const snap = await getDoc(doc(db, `users/${uid}`));
    const ret = snap.exists() && snap.data().verified;
    return ret;
}

export function onAuthStateChanged(listener) {
    return onAuthChanged(auth, listener);
}
