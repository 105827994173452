import React, { useState, useEffect, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { subscribeToPosts, deletePost, getPostDraft } from "../../firebase/db";
import { IconButton } from "../../components";
import { ReactComponent as EyeIcon } from "../../assets/eye.svg";
import { ReactComponent as EyeSlashIcon } from "../../assets/eye-slash.svg";
import "./Post.scss";

export function Post() {
    const [published, setPublished] = useState([]);
    const [unpublished, setUnpublished] = useState([]);

    useEffect(() => {
        const unsubs = [];
        unsubs.push(
            subscribeToPosts(
                (p) => setPublished(p),
                { type: "where", args: ["date.published", "!=", null] },
                { type: "orderBy", args: ["date.published", "desc"] }
            )
        );
        unsubs.push(
            subscribeToPosts(
                (p) => setUnpublished(p),
                { type: "where", args: ["date.published", "==", null] },
                { type: "orderBy", args: ["date.edited", "desc"] },
                { type: "orderBy", args: ["date.created", "desc"] }
            )
        );
        return () => unsubs.forEach((unsub) => unsub());
    }, []);

    return (
        <div className="content-wrap posts">
            <Link to="/posts/create" className="link-button">
                Create a new event/update.
            </Link>
            {unpublished.length > 0 && (
                <div className="post-list">
                    <h6>Unpublished</h6>
                    {unpublished.map((post, i) => (
                        <PostItem {...post} key={i} />
                    ))}
                </div>
            )}
            {published.length > 0 && (
                <div className="post-list">
                    <h6>Published</h6>
                    {published.map((post, i) => (
                        <PostItem {...post} key={i} />
                    ))}
                </div>
            )}
        </div>
    );
}

function PostItem({ type, title, date, eventDetails, visible, _id }) {
    const navigate = useNavigate();

    const [committed, setCommitted] = useState(false);
    useEffect(() => {
        getPostDraft(_id).then((draft) => setCommitted(draft.committed));
    }, [_id]);

    return (
        <div className="post">
            <div className="info">
                <div className="title">
                    {type}: {title || <i>Untitled</i>}
                </div>
                {date.published && committed === false && (
                    <div className="commit-notif">
                        You have unpublished changes!
                    </div>
                )}
                <div className="date">
                    {date.published
                        ? `Posted ${date.published.toLocaleString("en", {
                              dateStyle: "medium",
                              timeStyle: "short",
                          })}`
                        : "Not Published Yet."}
                </div>
                <div className="date">
                    {date.edited
                        ? `Last Edited ${date.edited.toLocaleString("en", {
                              dateStyle: "medium",
                              timeStyle: "short",
                          })}`
                        : `Created ${date.created.toLocaleString("en", {
                              dateStyle: "medium",
                              timeStyle: "short",
                          })}`}
                </div>
                {type === "event" ? (
                    <div className="event-date">
                        From{" "}
                        {eventDetails.start
                            ? eventDetails.start.toLocaleString("en", {
                                  dateStyle: "medium",
                                  timeStyle: "short",
                              })
                            : "UNSET"}{" "}
                        to{" "}
                        {eventDetails.end
                            ? eventDetails.end.toLocaleString("en", {
                                  dateStyle: "medium",
                                  timeStyle: "short",
                              })
                            : "UNSET"}
                    </div>
                ) : null}
                {date.published && (
                    <div className="visibility">
                        {visible ? (
                            <Fragment>
                                <EyeIcon /> Visible
                            </Fragment>
                        ) : (
                            <Fragment>
                                <EyeSlashIcon /> Hidden
                            </Fragment>
                        )}
                    </div>
                )}
            </div>
            <div className="operations">
                <IconButton
                    size={16}
                    style={{ paddingTop: 4, paddingBottom: 4 }}
                    icon="pencil"
                    onClick={() => navigate(`/posts/${_id}/edit`)}
                />
                <IconButton
                    size={16}
                    style={{ paddingTop: 4, paddingBottom: 4 }}
                    icon="trash"
                    onClick={() => deletePost(_id)}
                />
            </div>
        </div>
    );
}
