import { useState, useEffect, Fragment } from "react";
import { onAuthStateChanged, userIsVerified } from "./firebase";
import { getAppRoutes } from "./routes";
import { Navbar } from "./components";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AppContext } from "./hooks";
import "./css/App.scss";
import "react-toastify/dist/ReactToastify.css";

function App() {
    const [authed, setAuthed] = useState(false);
    const [accountIsVerifying, setAccountIsVerifying] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged((user) => {
            if (user) {
                setAccountIsVerifying(true);
                userIsVerified(user).then((isVerified) => {
                    setAccountIsVerifying(false);
                    if (isVerified) {
                        setAuthed(true);
                    } else {
                        setAuthed(false);
                        navigate("/unverified");
                    }
                });
            } else {
                setAuthed(false);
            }
        });
        return unsubscribe;
    }, []);

    const routes = getAppRoutes({ authed });

    return (
        <AppContext.Provider value={{ accountIsVerifying }}>
            <div className="app">
                <ToastContainer
                    autoClose={3000}
                    closeOnClick
                    position="bottom-left"
                />
                <Fragment>
                    {authed && <Navbar />}
                    <Routes>
                        {routes.map(({ path, element }, i) => (
                            <Route path={path} element={element} key={i} />
                        ))}
                    </Routes>
                </Fragment>
            </div>
        </AppContext.Provider>
    );
}

export default App;
